import { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import './card.css'
import { doc, deleteDoc } from "firebase/firestore";
import { useState } from 'react';
import { db } from '../../../firebase';

const Card = ({post, getPosts}) => {
  console.log(post)
    const{setCurrentArticle} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const deletePost = async () =>{
      console.log(post.id)
      const docRef = doc(db, "posts", post.id)
      setLoading(true)
      console.log('deleting')
      try {
         await deleteDoc(docRef)
         getPosts();
      } catch (error) {
        console.log(error)
        setError(error)
      }
     setLoading(false);
    }
    
  return (
    <div className='card' onClick={()=>setCurrentArticle(post.id)}>
        {loading?
        <div className="cardInfo">Please wait ...</div>
        :
        <>
          <div className="cardInfo">
            <h4 className="cardTitle">{post.title}</h4>
            <h6 >@{post.author.name}</h6>
          </div>
          <div className="cardActions">
            <button onClick={deletePost}>delete</button>
          </div>
        </>
        }
    </div>
  )
}

export default Card