import './footer.css'
import {SiGooglestreetview, SiAcademia} from 'react-icons/si'
import {AiOutlineMail} from 'react-icons/ai'
import {BsLinkedin,BsTwitter} from 'react-icons/bs'
import { useContext } from 'react'
import { ThemeContext } from '../../context'

const Footer = () => {
const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode

  const openNewTab = url =>{
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  return (
    <div className='f'>
      <div className="f-wrapper">
        <div className="f-left">
          <div className="f-left-title">
            <p>Contact Me</p>
          </div>
          
          <div className="f-row">
            <div className="f-row-icon"><AiOutlineMail/></div>
            <p className='f-row-text'><a className='f-email-link' href="mailto:info@Idiplotech.com" style={{color: darkMode ? 'white': 'black'}}>info@Idiplotech.com</a></p>
          </div>
          <div className="f-row f-row-address">
            <div className="f-row-icon"><SiGooglestreetview/></div>
            <div className="f-address">
              <p className="address-row">Plot 4, Pilkington road</p>
              <p className="address-row">Colline house</p>
              <p className="address-row">Kampala Uganda.</p>
            </div>
          </div>
        </div>
        <div className="f-center">
          <h4 className="f-center-title">Idiplotech</h4>
          <p className="f-center-text">"The best way to find yourself is to lose yourself in the service of others."</p>
          <div className="f-center-socials">
            <div className="f-social-icon" onClick={()=>openNewTab('https://at.linkedin.com/in/abusawule')}><BsLinkedin size={30}/></div>
            <div className="f-social-icon" onClick={()=>openNewTab('https://twitter.com/abusawule')}><BsTwitter size={30}/></div>
            <div className="f-social-icon" onClick={()=>openNewTab('https://annabellabjohnson.academia.edu/')}><SiAcademia size={30}/></div>
          </div>
        </div>
        <div className="f-right">
          <h4 className="f-right-title">Working Hours</h4>
          <div className="f-right-col">
            <p>Monday-Friday:</p>
            <p>09:00 Am - 4:00 Pm</p>
          </div>
          <div className="f-right-col">
            <p>Saturday-Sunday:</p>
            <p>N/A</p>
          </div>
        </div>
      </div>
      <div className="f-bottom-row">2022 Idiplotech. All rights reserved. </div>
    </div>
  )
}

export default Footer