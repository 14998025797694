import { useContext } from 'react';
import { ThemeContext } from '../../context';
import Hr_small from '../hr_small/hr_small';
import './industry_links.css'
import { linksData } from './links_data'

const IndustryLinks = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

  return (
    <section className="l">
        <h1 className="l-heading">Industry Links</h1>
        <div className="l-container">
            <div className="l-wrapper">
                {
                    linksData.map((item, idx)=>(
                        <>
                        <div className="l-row" key={idx} style={{backgroundColor: darkMode ? 'gray': 'whitesmoke'}} onClick={() => openInNewTab(item.link)}>
                            <div className="l-row-title">
                                {item.title}
                                {/* <a className='l-email-link' href={item.link} style={{color: darkMode ? 'white': 'black'}}>{item.title}</a> */}
                            </div>
                        </div>
                        </>
                    ))
                }
            </div>
        </div>
    </section>
  )
}

export default IndustryLinks