import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import './login.css'
const Login = () => {
  const navigate = useNavigate();
    const [err, setErr] = useState(true);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoading(true);
        const email = e.target[0].value;
        const password = e.target[1].value;

        try {
            setErr(false);
            //Login
            const res = await signInWithEmailAndPassword(auth, email, password);
            setLoading(false);
            navigate('/admin');
        } catch (error) {
          console.log(error)
            // const errorCode = error.code;
            // const errorMessage = error.message;
            setLoading(false);
            setErr(true);
        }
     }

  return (
    <div className='formContainer'>
        <div className="formWrapper">
            <span className="logo">Idiplotech</span>
            <span className="title">Login</span>
            <form className='form' onSubmit={handleSubmit}>
                <input className='input' type="email"  placeholder='email'/>
                <input className='input' type="password" placeholder='password' />
                <button className='button' disabled={loading}>{loading? "Please wait...":"Log in"}</button>
                {err && <span>An error occurred, please try again later</span>}
            </form>
        </div>
    </div>
  )
}

export default Login