import './article_admin.css'
import Main from './main/Main'
import Sidebar from './sidebar/Sidebar'

const ArticleAdmin = () => {
  return (
    <div className='articleAdmin'>
        <div className="articleContainer">
            <Sidebar />
            <Main/>
        </div>
    </div>
  )
}

export default ArticleAdmin