import './components/intro/intro.css'
import { collection, getDocs } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from './firebase'
import Intro from "./components/intro/intro";
import Services from './components/services/services';
import Hr from './components/hr/hr';
import Articles from './components/articles/Articles';
import Footer from './components/footer/Footer';
import Toggle from './components/toggle/Toggle';
import { useContext } from 'react';
import { ThemeContext } from './context';
import Login from './components/login/Login';
import ArticleAdmin from './components/admin/ArticleAdmin';
import Register from './components/register/Register';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './context/authContext';
import IndustryLinks from './components/industry_links/industry_links';
import { linksData } from './components/industry_links/links_data';

function App() {
   const [postLists, setPostList] = useState([]);
  const{currentUser} = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode

  useEffect(() => {
    const postsCollectionRef = collection(db, "posts");
    const getPosts = async () => {
    const data = await getDocs(postsCollectionRef);
    setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
  };
    getPosts();
  }, []);


  return (
  <>
    <Toggle/>
    <div style={{backgroundColor: darkMode? '#222': 'white', color: darkMode && 'white'}}>
      <Routes>
        <Route path='/'>
          <Route index element={<>
            <Intro/>
            <Hr />
            <Services/>
            <Hr/>
            {postLists.length > 0 && 
            <>
              <Articles postLists={postLists}/>
              <Hr/>
            </>
            }
            {
            /* TODO 
              - Industry news ## Already done...move from articles section to independent section
              - Other resources
            */
            }
            {linksData.length > 0 &&
              <IndustryLinks />}
            <Hr/>
            <Footer/>
          </>} />
        </Route>
        <Route path='register' element={<Register />} />
        <Route path='login' element={<Login />} />
        <Route path='admin' element={currentUser ? <ArticleAdmin />: <Navigate to='/'/>} />
      </Routes>
      
    </div>
  </>
  );
}

export default App;
