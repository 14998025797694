import './toggle.css'
import{FiSun} from 'react-icons/fi'
import{FaMoon} from 'react-icons/fa'
import { useContext } from 'react';
import { ThemeContext } from '../../context';


const Toggle = () => {
  const theme = useContext(ThemeContext);
  const handleClick =()=>{
    theme.dispatch({type: 'TOGGLE'});
  }
  return (
    <div className='t'>
        <div className="t-icon"><FiSun/></div>
        <div className="t-icon"><FaMoon/></div>
        <div className="t-button" 
          onClick={handleClick}
          style={{left: theme.state.darkMode ? 0 : 25}}
        ></div>
    </div>
  )
}

export default Toggle