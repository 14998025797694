export const linksData = [
    {
        id: 1,
        title: "The government of Uganda",
        link: "https://www.gou.go.ug/",
        site: "www.gou.co.ug",
    },
    {
        id: 2,
        title: "Government Citizen Interaction Centre (GCIC)",
        link: "https://www.gcic.go.ug/",
        site: "www.gcic.go.ug",
    },
    {
        id: 3,
        title: "Uganda Investment Authority",
        link: "https://www.ugandainvest.go.ug/",
        site: "ugandainvest.go.ug",
    },
    {
        id: 4,
        title: "Uganda Communications Commission",
        link: "https://ucc.co.ug",
    },
    {
        id: 5,
        title: "Uganda Revenue Authority",
        link: "https://www.ura.go.ug/",
    },
    {
        id: 6,
        title: "The Computer Misuse Act, 2011.",
        link: "https://chapterfouruganda.org/sites/default/files/downloads/Computer-Misuse-Act-2011_0.pdf",
    },
    {
        id: 7,
        title: "Ministry of ICT and National Guidance",
        link: "https://ict.go.ug/initiatives/digital-uganda-vision/",
    },
    {
        id: 8,
        title: "National Information Technology Authority Uganda",
        link: "https://ict.go.ug/about-us/agencies/nita-uganda/",
    },
    {
        id: 9,
        title: "National ICT Innovation Hub",
        link: "https://niisp.ict.go.ug/innovation-hub",
    },
    {
        id: 10,
        title: "Ministry of Works & Transport",
        link: "https://www.works.go.ug/",
    },
    {
        id: 11,
        title: "Ministry of Foreign Affairs Uganda",
        link: "https://www.mofa.go.ug/",
    },
    {
        id: 12,
        title: "Consular Services",
        link: "https://www.mofa.go.ug/data/smenu/129/Consular%20Services.html",
    },
    {
        id: 13,
        title: "Embassies & Consulates",
        link: "https://www.gou.go.ug/content/embassies-consulates",
    },
    {
        id: 14,
        title: "Visit Uganda",
        link: "https://www.gou.go.ug/visit-uganda",
    },
    {
        id: 15,
        title: "United Nations in Uganda",
        link: "https://uganda.un.org/",
    },
    {
        id: 16,
        title: "United Nations Office of Information Communication Technology",
        link: "https://unite.un.org/",
    },
    {
        id: 17,
        title: "Public Relations & Information in Uganda",
        link: "https://www.prau.ug/",
    },
    {
        id: 18,
        title: "Ministry of Security",
        link: "https://security.go.ug/",
    },
    {
        id: 19,
        title: "Covid in Uganda",
        link: "https://covid19.gou.go.ug",
    },
    {
        id: 20,
        title: "Uganda People’s Defence Forces",
        link: "https://www.updf.go.ug/",
    },
    {
        id: 21,
        title: "Uganda National Oil Company",
        link: "https://www.unoc.co.ug/",
    },
    {
        id: 22,
        title: "Uganda ICT Association",
        link: "https://ictau.ug/",
    },
    {
        id: 23,
        title: "Makerere University College of Computing and Information Sciences",
        link: "https://cis.mak.ac.ug/",
    },
    {
        id: 24,
        title: "ICT Teachers' Association of Uganda [ITAU]",
        link: "https://www.ictteachersug.net/",
    },
    {
        id: 25,
        title: "Modern Diplomacy",
        link: "https://moderndiplomacy.eu",
    },
    {
        id: 26,
        title: "Citizen Diplomacy",
        link: "https://www.centerforcitizendiplomacy.org",
    },
    {
        id: 26,
        title: "Global Citizen",
        link: "https://www.globalcitizen.org/en/",
    },
    {
        id: 26,
        title: "Austria Uganda Friendship Society",
        link: "https://www.austria-uganda.at/",
    },
    {
        id: 26,
        title: "Ugandans in Austria",
        link: "http://www.uganda.at/ugandas_in_the_diaspora/ugandas_in_the_diaspora.htm",
    },
]