import Article from '../article/Article'
import './articles.css'

const Articles = ({postLists}) => {
  const openNewTab = url =>{
    window.open(url, '_blank', 'noopener, noreferrer');
  };
  const list = [
    {
      id: 1,
      title: 'Article1 link test',
      link: 'http://google.com',
    },{
      id: 2,
      title: 'Article2 link test',
      link: 'http://google.com',
    }
  ]

  

  return (
    <div className='articles'>
        <h1 className="articles-heading">Articles</h1>
        <div className="articles-wrapper">
          <div className="articles-featured">
            <Article postLists={postLists} />
          </div>
          <div className="articles-of-interest">
            <div className="articles-i-header">Articles of interest</div>
            <div className='i-hr'> <hr /></div>
            <div className="article-links-wrapper">
              {list.length > 0?
              list.map((item, idx)=>(
                <div className="interest-row" key={item.id} onClick={()=>openNewTab(item.link)}>
                  <p className='article-number'>{idx +1}: </p>
                  <p>{item.title}</p>
                </div>
              ))
              :
              <div className="no-items">No new items</div>
              }
            </div>
          </div>
        </div>
    </div>
  )
}

export default Articles