import './services.css'
import {GrDocumentConfig} from 'react-icons/gr'
import{FcWorkflow} from 'react-icons/fc'
import {GiTeamUpgrade,GiPublicSpeaker} from 'react-icons/gi'
import { RiTeamFill} from 'react-icons/ri'
import {FaProjectDiagram} from 'react-icons/fa'
import {TbDeviceAnalytics} from 'react-icons/tb'
import {SiMicrostrategy} from 'react-icons/si'
import {MdSyncProblem} from 'react-icons/md'
import {FcDocument} from 'react-icons/fc'

const Services = () => {
  return (
    <div className='s'>
        <h1 className="s-heading">Expertise</h1>
        <div className="s-wrapper">
            <div className="s-left">
                <div className="s-items-wrapper">
                    <div className="s-item">
                        <div className="s-icon"><GrDocumentConfig/></div>
                        <p className='s-item-text'>Software configuration management</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><FcWorkflow/></div>
                        <p className='s-item-text'>Resource / Workflow allocation</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><GiTeamUpgrade/></div>
                        <p className='s-item-text'>Team training & leadership</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><RiTeamFill/></div>
                        <p className='s-item-text'>Relationship building and management</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><GiPublicSpeaker/></div>
                        <p className='s-item-text'>Public speaking</p>
                    </div>
                </div>
            </div>
            <div className="s-right">
                <div className="s-items-wrapper">
                    <div className="s-item">
                        <div className="s-icon"><FaProjectDiagram/></div>
                        <p className='s-item-text'>Software project planning and management</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><TbDeviceAnalytics/></div>
                        <p className='s-item-text'>Requirements gathering and analysis</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><SiMicrostrategy/></div>
                        <p className='s-item-text'>Strateging planning and implementation</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><MdSyncProblem/></div>
                        <p className='s-item-text'>Problem solving and issue resolution</p>
                    </div>
                    <div className="s-item">
                        <div className="s-icon"><FcDocument/></div>
                        <p className='s-item-text'>Documentation</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services