import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { auth } from "../firebase";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) =>{
    const [currentUser, setCurrentUser] = useState({});
    const [currentArticle, setCurrentArticle] = useState();
    const [createArticle, setCreateArticle] = useState(false);

    useEffect(()=>{
        const unsub = onAuthStateChanged(auth, (user)=>{
            setCurrentUser(user);
            console.log(user);
        });

        return ()=>{
            unsub();
        };
    },[]);

    return(
        <AuthContext.Provider value={{currentUser, currentArticle, setCurrentArticle,createArticle, setCreateArticle}}>
            {children}
        </AuthContext.Provider>
        );
}