import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import '../login/login.css'
const Register = () => {
    const navigate = useNavigate();
    const [err, setErr] = useState(true);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e)=>{
        e.preventDefault();
        const displayName = e.target[0].value;
        const email = e.target[1].value;
        const password = e.target[2].value;

        try {
            setErr(false);
            setLoading(true);
            //create the user
            const res = await createUserWithEmailAndPassword(auth, email, password);
            //update the profile data
            await updateProfile(res.user, {
                displayName
            });
            //update the firestore database
            await setDoc(doc(db, "users", res.user.uid),{
                uid: res.user.uid,
                displayName,
                email,
            })
            setLoading(false);
            navigate('/admin');
        } catch (error) {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            setErr(true);
            setLoading(false);
        }
     }
  return (
    <div className='formContainer'>
        <div className="formWrapper">
            <span className="logo">Idiplotech</span>
            <span className="title">Register</span>
            <form className='form' onSubmit={handleSubmit}>
                <input className='input' type="text" placeholder='display name' />
                <input className='input' type="email"  placeholder='email'/>
                <input className='input' type="password" placeholder='password' />
                <button className='button'disabled={loading}>{loading? "Please wait...":"Register"}</button>
                {err && <span>Something went wrong, please try again later</span>}
            </form>
        </div>
    </div>
  )
}

export default Register