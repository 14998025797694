import { signOut } from 'firebase/auth'
import { useContext } from 'react';
import { AuthContext } from '../../../context/authContext';
import { auth } from '../../../firebase'
import './sidebar.css'

const Sidebar = () => {
    const{setCreateArticle,setCurrentArticle} = useContext(AuthContext);

  return (
    <div className='sidebar'>
      <div className="sidebar_item" onClick={()=>{
        setCurrentArticle();
        setCreateArticle(false);
        }}>
        My articles
      </div>
      <div className="sidebar_item" onClick={()=>setCreateArticle(true)}>
        Create new
      </div>
      <div className="sidebar_item">
        Link external article
      </div>
      <div className="logout" onClick={()=>signOut(auth)}>
        Log out
      </div>
    </div>
  )
}

export default Sidebar