import React from 'react'
import annabellaImg from '../../img/anabella_main2.jpg'

const Intro = () => {
  return (
    <div className='a'>
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img src={annabellaImg} alt="" className="a-img" />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">Annabella Busawule JOHNSON</h1>
        <p className="a-desc">
          A vision and purpose-driven technical professional who offers a unique blend of skills, experience, and perspective vital to organizational success in today's dynamic and competitive landscape. With a progressive, 22-year career that includes start-ups, non-profits, private corporations, academic institutions, consulate and embassy, and international organizations.
        </p>
        <p className="a-desc">I am always eager to inspire positive change, drive technical innovation, and support the achievement of ambitious goals in the countries, organizations and teams that I am given an opportunity to engage with.</p>
        <p className="a-desc">
        I am on the Working Group of the ITU Partner2Connect Digital Coalition(2021), an Approved/Full participant for the Expert Communication Systems- CTBTO (2019), Have a Bestallungsdiplom Generalkonsulat der Republik Uganda in Öesterreich (2019).
        </p>
        <div className="a-award">
          <h4 className="a-award-title">Qualifications and awards</h4>
          <p className="a-award-desc">I have a MSc in Software Engineering, a BSc in Computer Information Systems and a PgD in Peace Studies and Conflict Resolution.</p>
        </div>
      </div>
    </div>
  )
}

export default Intro