import './hr.css'

const Hr = () => {
  return (
    <div className='section-separator'>
        <hr className='s-hr' />
    </div>
  )
}

export default Hr