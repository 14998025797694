import { useState,useContext } from 'react'
import { AuthContext } from '../../context/authContext';
import SingleArticle from './SingleArticle'
import ArticleCard from './ArticleCard'
import './article.css'
import { useEffect } from 'react';

const Article = ({postLists}) => {
    const [showFullText, setShowFullText] = useState(false)
    const [showArticles, setShowArticles] = useState(false)
    const [featuredArticle, setFeaturedArticle] = useState();
    const{currentArticle, setCurrentArticle} = useContext(AuthContext);

    useEffect(()=>{
        postLists?.length > 0 && setFeaturedArticle(postLists[0])
    },[postLists])

    useEffect(()=>{
        console.log(postLists)
        console.log(featuredArticle)
    },[postLists])

  return (
    <div className='article'>
        { showArticles?
            postLists.map((post, idx)=>(
          <ArticleCard key={idx} post={post} index={idx} showAll={setShowArticles}/>
        ))
        :
        currentArticle?
        <SingleArticle/>
            :
            <>
            <div className="article-title">Featured article</div>
        {!showFullText && 
            <div className="article-short-desc">
                <p className='article-text'>
                    {featuredArticle?.postText}
                </p>
                <button className='article_btn'onClick={()=>setShowFullText(true)}>more</button>
            </div>
        }
        {showFullText && <div className="article-full-text">
            <p>
                {featuredArticle?.postText}
            </p>
        </div>}
        </>
    }
        {!showArticles && <button className='article_btn' onClick={()=>setShowArticles(true)}>All articles</button>}
        {(currentArticle || showArticles) && <button className='article_btn' onClick={()=>{
            setShowArticles(false)
            setCurrentArticle()
            }}>close</button>}
    </div>
  )
}

export default Article