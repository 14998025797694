import { addDoc, collection } from 'firebase/firestore';
import { useContext, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { auth, db } from '../../../firebase';
import './create_article.css'

const CreateArticle = () => {
    const{setCreateArticle} = useContext(AuthContext);
    const [title, setTitle] = useState("");
    const [postText, setPostText] = useState("");
    const [loading, setLoading] = useState(false);

    const postsCollectionRef = collection(db, "posts");

    const createPost = async () => {
        setLoading(true);
        await addDoc(postsCollectionRef, {
        title,
        postText,
        author: { name: auth.currentUser.displayName, id: auth.currentUser.uid },
        });
        setLoading(false);
        setCreateArticle(false);
    };

  return (
    <div className='create_post'>
        <div className="cp_container">
            <h1>Create a post</h1>
            <div className="cp_inputgp">
                <label htmlFor="">Title:</label>
                <input type="text" placeholder='Title...' onChange={(event) => {
              setTitle(event.target.value);
            }}/>
            </div>
            <div className="cp_inputgp">
                <label htmlFor="">Post:</label>
                <textarea type="text" placeholder='Post...' onChange={(event) => {
              setPostText(event.target.value);
            }}/>
            </div>
            <button disabled={loading || !title || !postText} onClick={createPost}>{loading? "Please wait..":"Submit"}</button>
            <button disabled={loading} onClick={()=>setCreateArticle(false)}>Cancel</button>
        </div>
    </div>
  )
}

export default CreateArticle