import { useContext } from 'react';
import { AuthContext } from '../../context/authContext';

const ArticleCard = ({post, index, showAll}) => {
    const{setCurrentArticle} = useContext(AuthContext);
    
  return (
    <div key={index} className='article_card' onClick={()=>{
        setCurrentArticle(post.id);
        showAll(false);
        }}>
        <div className="article_cardInfo">
            <h4 className="article_cardTitle">{post.title}</h4>
            <h6 >@{post.author.name}</h6>
            
        </div>
    </div>
  )
}

export default ArticleCard