import { doc, getDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/authContext';
import { db } from '../../firebase';

const SingleArticle = () => {
    const{currentArticle,setCurrentArticle} = useContext(AuthContext);
    const [article, setArticle] = useState();
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(false);
    useEffect(() => {
        const docRef = doc(db, "posts", currentArticle);
        const getDocument = async ()=>{
            setLoading(true);
            if(currentArticle){
                try {
                        const docSnap = await getDoc(docRef);
        
                        if (docSnap.exists()) {
                        setArticle(docSnap.data())
                        } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                        }
                    
                } catch (error) {
                  console.log(error)  
                }
            }
            setLoading(false);
        }
        getDocument();
  }, [currentArticle]);

  return (<>
    {loading ? 
    <div className="loading_indicator">Please wait ...</div>
    :
    err?
    <div className="error">An error occured, please try again later</div>
    :
    <div className='single_article'>
        <h1 className="article_title">{article?.title}</h1>
        <h4>@{article?.author.name}</h4>
        <div className="article_text">{article?.postText}</div>
    </div>
    }
  </>)
}

export default SingleArticle