// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC76mIwboNsX3dwzM3FflCswxbSbYD6lBE",
  authDomain: "idiplotech.firebaseapp.com",
  projectId: "idiplotech",
  storageBucket: "idiplotech.appspot.com",
  messagingSenderId: "14377889574",
  appId: "1:14377889574:web:029bb668ca77cff1411be7",
  measurementId: "G-JQKHCCJ46B"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const db = getFirestore()
//const analytics = getAnalytics(app);