import { collection, deleteDoc, doc, getDocs, onSnapshot } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/authContext';
import { db } from '../../../firebase';
import Card from '../article_card/Card'
import CreateArticle from '../create_article/CreateArticle';
import SingleArticle from '../single_article/SingleArticle';
import './main.css'

const Main = () => {
  const{currentArticle, createArticle} = useContext(AuthContext);

  const [postLists, setPostList] = useState([]);
  const [refreshData, setRefreshData] = useState(false);
  const postsCollectionRef = collection(db, "posts");

  const deletePost = async (id) => {
    const postDoc = doc(db, "posts", id);
    await deleteDoc(postDoc);
  };

  const getPosts = async () => {
    const data = await getDocs(postsCollectionRef);
    setPostList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(()=>{
    !createArticle && setRefreshData(!refreshData);
  },[createArticle])

  useEffect(() => {
    getPosts();
  }, [refreshData]);

  

  return (
    <div className='main'>
      <div className="main_title">Articles</div>
      <div className="main_wrapper">
        {currentArticle?
          <SingleArticle/>
        :
        createArticle?
          <CreateArticle/>
        :
        postLists.map((post)=>(
          <Card post={post} getPosts={getPosts}/>
        ))
      }
      </div>
    </div>
  )
}

export default Main